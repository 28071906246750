@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "tom-select/dist/css/tom-select";
@import "flatpickr/dist/flatpickr.css";

/* Custom z-index for tom-select dropdown */
.tom-select .ts-dropdown {
  z-index: 50; /* Adjust the z-index as needed */
}

.pagy {
  @apply flex space-x-1 font-semibold text-sm text-gray-500;
  a:not(.gap) {
    @apply block rounded-lg px-3 py-1 bg-gray-200;
    &:hover {
      @apply bg-gray-300;
    }
    &:not([href]) {
      /* disabled links */
      @apply text-gray-300 bg-gray-100 cursor-default;
    }
    &.current {
      @apply text-white bg-gray-400;
    }
  }
  label {
    @apply inline-block whitespace-nowrap bg-gray-200 rounded-lg px-3 py-0.5;
    input {
      @apply bg-gray-100 border-none rounded-md;
    }
  }
}

/* Custom alert classes */
.alert-notice {
  @apply alert-success;
}
